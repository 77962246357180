import { NextPage } from 'next';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';

import Analyzer from '../analytics';

const aniqueMainUrl = 'https://anique.jp';

export type User = {
  [key: string]: string | undefined;
  sub?: string;
  email: string;
};

export type Auth0Redirect = {
  to: string;
  query?: Record<string, string | string[] | undefined>;
};

export interface Auth0Context {
  user?: User;
  login(redirect?: Auth0Redirect): void;
  signup(redirect?: Auth0Redirect): void;
  logout(): void;
}

const context = React.createContext<Auth0Context | null>(null);

export const useAuth0 = (): Auth0Context => useContext(context)!;

type Props = {
  user?: User;
  token?: string | undefined;
};

const authQueryParam = '?authMethod=login';

const Auth0Provider: NextPage<Props> = ({ user, children }) => {
  const { locale } = useRouter();

  // MEMO: 中国語繁体字の場合は、そのままlanguage codeが使えないので、auth0用に変換する。
  // https://auth0.com/docs/customize/internationalization-and-localization/universal-login-internationalization
  const auth0Locale = locale === 'zh_Hant' ? 'zh-TW' : locale;

  useEffect(() => {
    if (user) {
      Analyzer.identify(user.sub as string);
    }
  }, [user]);

  const signup = (redirect?: Auth0Redirect, hasTransition?: boolean) => {
    if (typeof window === 'undefined') {
      return;
    }

    const query = redirect
      ? queryString.stringify({
          locale: auth0Locale,
          returnTo: redirect.to,
          screenHint: 'signup',
          ...redirect.query,
        })
      : undefined;
    window.location.href = query ? `/api/login?${query}` : '/api/login';

    if (hasTransition) {
      window.history.replaceState(window.history.state, '', authQueryParam);
    }
  };

  const login = (redirect?: Auth0Redirect, hasTransition?: boolean) => {
    if (typeof window === 'undefined') {
      return;
    }

    const query = redirect
      ? queryString.stringify({
          locale: auth0Locale,
          returnTo: redirect.to,
          ...redirect.query,
        })
      : undefined;
    window.location.href = query ? `/api/login?${query}` : '/api/login';

    if (hasTransition) {
      window.history.replaceState(window.history.state, '', authQueryParam);
    }
  };

  const logout = (returnTo = aniqueMainUrl) => {
    if (typeof window === 'undefined') {
      return;
    }

    window.location.href = `/api/logout?returnTo=${returnTo}`;
  };

  return (
    <context.Provider value={{ user, signup, login, logout }}>
      {children}
    </context.Provider>
  );
};

export default Auth0Provider;
