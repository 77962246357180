import { SegmentTrackData } from '../../../interfaces';

const events = {
  OPEN_MENU: 'Open Menu',
  TAP_MENU_BOOTH: 'Tap Menu Booth',
  ENTER_SHOP: 'Enter Shop',
  CREATE_INVITE_MUSEUM_ROOM_CODE: 'Create Invite Museum Room Code',
  SHOW_NOTIFIER: 'Show Notifier',
  SELECT_ACTIVITY_TAB: 'Select Activity Tab',
  SELECT_REACTION_TAB: 'Select Reaction Tab',
  CLICK_NOTIFICATION: 'Click Notification',
  SHOW_PROFILE: 'Show Profile',
};

const openMenu = (
  opened: boolean,
  museum_slug: string,
  booth_id: string,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_MENU,
    payload: {
      opened,
      museum_slug,
      booth_id,
    },
  };
};

const tapMenuBooth = (
  museum_slug: string,
  booth_id: string,
): SegmentTrackData => {
  return {
    eventName: events.TAP_MENU_BOOTH,
    payload: {
      museum_slug,
      booth_id,
    },
  };
};

const enterShop = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.ENTER_SHOP,
    payload: {
      museum_slug,
    },
  };
};

const showNotifier = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.SHOW_NOTIFIER,
    payload: {
      museum_slug,
    },
  };
};

const tapActivityTab = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.SELECT_ACTIVITY_TAB,
    payload: {
      museum_slug,
    },
  };
};

const tapReactionTab = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.SELECT_REACTION_TAB,
    payload: {
      museum_slug,
    },
  };
};

const clickNotification = (
  museum_slug: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.CLICK_NOTIFICATION,
    payload: {
      museum_slug,
      artwork_id,
    },
  };
};

const showProfile = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.SHOW_PROFILE,
    payload: {
      museum_slug,
    },
  };
};

const menuEvents = {
  openMenu,
  tapMenuBooth,
  enterShop,
  showNotifier,
  tapActivityTab,
  tapReactionTab,
  clickNotification,
  showProfile,
};

export default menuEvents;
