import { AuthAction, AuthMethod, SegmentTrackData } from '../../../interfaces';

const events = {
  OPEN_AUTH: 'Open Auth Modal',
  AUTH: 'Tap Auth Button',
};

const openAuth = (
  museumSlug: string,
  boothId: string,
  artworkId: string,
  action: AuthAction,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_AUTH,
    payload: {
      museum_slug: museumSlug,
      booth_id: boothId,
      artwork_id: artworkId,
      action,
    },
  };
};

const authorization = (
  museumSlug: string,
  boothId: string,
  artworkId: string,
  action: AuthAction,
  method: AuthMethod,
): SegmentTrackData => {
  return {
    eventName: events.AUTH,
    payload: {
      museum_slug: museumSlug,
      booth_id: boothId,
      artwork_id: artworkId,
      action,
      method,
    },
  };
};

const authEvents = { openAuth, authorization };

export default authEvents;
