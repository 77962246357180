import segmentPlugin from '@analytics/segment';
import Analytics, { AnalyticsInstance } from 'analytics';

import { SegmentTrackData } from '../../interfaces';

class Analyzer {
  private static analytics: AnalyticsInstance;
  // TODO: cookie consentでtrackを許可されているかどうかのフラグ

  public static init(
    appId: string,
    segmentWriteKey: string,
    integrations: Record<string, boolean>,
  ): void {
    if (Analyzer.analytics) {
      return;
    }

    if (!integrations) {
      return;
    }

    console.log('Analyzer:init', appId, integrations);
    Analyzer.analytics = Analytics({
      app: appId,
      plugins: [
        segmentPlugin({
          writeKey: segmentWriteKey,
          integrations,
        }),
      ],
    });
  }

  public static createIntegrations(
    marketingAndAnalytics: boolean,
  ): Record<string, boolean> {
    return {
      Amplitube: marketingAndAnalytics,
      'Facebook Pixel': false,
      'Google AdWords New': false,
      'Google Analytics': marketingAndAnalytics,
      Hotjar: marketingAndAnalytics,
      'Twitter Ads': false,
      Sentry: marketingAndAnalytics,
    };
  }

  public static page(path: string): void {
    if (!Analyzer.analytics) {
      return;
    }

    console.log('Analyzer:page', path);
    Analyzer.analytics.page({
      path,
    });
  }

  // userId: auth0ユーザーのsub
  public static identify(userId: string): void {
    if (!Analyzer.analytics) {
      return;
    }

    console.log('Analyzer:identify', userId);
    Analyzer.analytics.identify(userId);
  }

  public static track({ eventName, payload }: SegmentTrackData): void {
    if (!Analyzer.analytics) {
      return;
    }

    console.log('Analyzer:track', eventName, payload);
    Analyzer.analytics.track(eventName, payload);
  }
}

export default Analyzer;
export * from './events';
