import _differenceBy from 'lodash/differenceBy';

import { MuseumTicketData } from '../interfaces/museum';

const storageKeys = {
  audioSetting: 'museum_user-audio_setting',
  tutorials: 'museum_user-tutorials',
  newTickets: 'museum_user-new_tickets',
  languageSetting: 'museum_user-language_setting',
  integrations: 'museum_user-analytics_integrations',
};

export type AudioSetting = {
  muted: boolean;
};

export type Tutorial = {
  [key: string]: boolean;
};

// for debug.
function resetLocal(): void {
  localStorage.clear();
}

function getAudioSetting(): AudioSetting | undefined {
  const item = localStorage.getItem(storageKeys.audioSetting);
  if (!item) {
    return undefined;
  }

  return JSON.parse(item);
}

function setAudioSetting(setting: AudioSetting): void {
  const item = JSON.stringify(setting);
  localStorage.setItem(storageKeys.audioSetting, item);
}

function removeAudioSetting(): void {
  localStorage.removeItem(storageKeys.audioSetting);
}

function isShownTutorials(slug: string): boolean {
  const item = localStorage.getItem(storageKeys.tutorials);
  if (!item) {
    return false;
  }

  const tutorials = JSON.parse(item);
  return tutorials[slug];
}

function setShownTutorials(slug: string): void {
  const current = localStorage.getItem(storageKeys.tutorials) ?? `{}`;
  const tutorials = JSON.parse(current);
  tutorials[slug] = true;

  const item = JSON.stringify(tutorials);
  localStorage.setItem(storageKeys.tutorials, item);
}

function isShownTickets(tickets: MuseumTicketData[]): boolean {
  const shownTickets = getShownTickets();
  if (!shownTickets) {
    return false;
  }

  return _differenceBy(shownTickets, tickets, 'id').length === 0;
}

function getShownTickets(): MuseumTicketData[] | null {
  const item = localStorage.getItem(storageKeys.newTickets);
  if (!item) {
    return null;
  }

  return JSON.parse(item);
}

function setShownTickets(tickets: MuseumTicketData[]): void {
  localStorage.setItem(storageKeys.newTickets, JSON.stringify(tickets));
}

function setAnalyticsIntegrations(integrations: Record<string, boolean>): void {
  localStorage.setItem(storageKeys.integrations, JSON.stringify(integrations));
}

function getAnalyticsIntegrations(): Record<string, boolean> | null {
  const integrations = localStorage.getItem(storageKeys.integrations);
  if (!integrations) {
    return null;
  }

  return JSON.parse(integrations);
}
export {
  getAnalyticsIntegrations,
  getAudioSetting,
  isShownTickets,
  isShownTutorials,
  removeAudioSetting,
  resetLocal,
  setAnalyticsIntegrations,
  setAudioSetting,
  setShownTickets,
  setShownTutorials,
};
