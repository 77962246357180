import { SegmentTrackData } from '../../../interfaces';

const events = {
  TAP_GATE: 'Tap Gate',
  ENTER: 'Enter Booth',
  LEAVE: 'Leave Booth',
};

const tapGate = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.TAP_GATE,
    payload: {
      museum_slug,
    },
  };
};

const enter = (museum_slug: string, booth_id: string): SegmentTrackData => {
  return {
    eventName: events.ENTER,
    payload: {
      museum_slug,
      booth_id,
    },
  };
};

const leave = (
  museum_slug: string,
  booth_id: string,
  duration: number,
): SegmentTrackData => {
  return {
    eventName: events.LEAVE,
    payload: {
      museum_slug,
      booth_id,
      duration,
    },
  };
};

const boothEvents = {
  tapGate,
  enter,
  leave,
};

export default boothEvents;
