import { CommentLikeFrom, SegmentTrackData } from '../../../interfaces';

const events = {
  ZOOM_IN: 'Zoom In Artwork',
  ZOOM_OUT: 'Zoom Out Artwork',
  SWIPE: 'Swipe Artwork',
  LEAVE: 'Leave Artwork',
  MOVIE_PLAY: 'Movie Play',
  VOICE_PLAY: 'Voice Play',
  OPEN_CAPTION: 'Open Caption',
  OPEN_FEED_CAPTION: 'Open Feed Caption',
  OPEN_RECENT_COMMENT: 'Open Recent Comment',
  TAP_FEED_COMMENT: 'Tap Feed Comment',
  POSTED_COMMENT: 'Create Museum Artwork Comment',
  DELETE_COMMENT: 'Delete Museum Artwork Comment',
  REPORT_COMMENT: 'Report Museum Artwork Comment',
  REPLY_COMMENT: 'Create Museum Artwork Reply Comment',
  OPEN_MENTION: 'Open Mention',
  CLOSE_MENTION: 'Close Mention',
  COMMENT_LIKE: 'Create Museum Artwork Comment Like',
  COMMENT_UNLIKE: 'Delete Museum Artwork Comment Like',
  LIKE: 'Create Museum Artwork Like',
  UNLIKE: 'Delete Museum Artwork Like',
  CLICK_SORT: 'Click Sort',
};

const zoomIn = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.ZOOM_IN,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const zoomOut = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.ZOOM_OUT,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const swipe = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.SWIPE,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const leave = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  duration: number,
): SegmentTrackData => {
  return {
    eventName: events.LEAVE,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      duration,
    },
  };
};

const moviePlay = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.MOVIE_PLAY,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const voicePlay = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.VOICE_PLAY,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const openCaption = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_CAPTION,
    payload: {
      opened: true,
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const closeCaption = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_CAPTION,
    payload: {
      opened: false,
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const openFeedCaption = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.OPEN_FEED_CAPTION,
    payload: {
      opened: true,
      museum_slug,
    },
  };
};

const openRecentComment = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.OPEN_RECENT_COMMENT,
    payload: {
      opened: true,
      museum_slug,
    },
  };
};

const closeRecentComment = (museum_slug: string): SegmentTrackData => {
  return {
    eventName: events.OPEN_RECENT_COMMENT,
    payload: {
      opened: false,
      museum_slug,
    },
  };
};

const tapFeedComment = (
  museum_slug: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.TAP_FEED_COMMENT,
    payload: {
      museum_slug,
      artwork_id,
    },
  };
};

const like = (museum_slug: string, artwork_id: string): SegmentTrackData => {
  return {
    eventName: events.LIKE,
    payload: {
      museum_slug,
      artwork_id,
    },
  };
};

const unlike = (museum_slug: string, artwork_id: string): SegmentTrackData => {
  return {
    eventName: events.UNLIKE,
    payload: {
      museum_slug,
      artwork_id,
    },
  };
};

const likeComment = (
  museum_slug: string,
  comment_id: string,
  from: CommentLikeFrom,
): SegmentTrackData => {
  return {
    eventName: events.COMMENT_LIKE,
    payload: {
      museum_slug,
      museum_artwork_comment_id: comment_id,
      from,
    },
  };
};

const unlikeComment = (
  museum_slug: string,
  comment_id: string,
  from: CommentLikeFrom,
): SegmentTrackData => {
  return {
    eventName: events.COMMENT_UNLIKE,
    payload: {
      museum_slug,
      museum_artwork_comment_id: comment_id,
      from,
    },
  };
};

const postedComment = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  comment: string,
): SegmentTrackData => {
  return {
    eventName: events.POSTED_COMMENT,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      comment,
    },
  };
};

const clickSort = (
  museum_slug: string,
  artwork_id: string,
  sort_type: string,
): SegmentTrackData => {
  return {
    eventName: events.CLICK_SORT,
    payload: {
      museum_slug,
      artwork_id,
      sort_type,
    },
  };
};

const deleteComment = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  comment_id: string,
): SegmentTrackData => {
  return {
    eventName: events.DELETE_COMMENT,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      comment_id,
    },
  };
};

const replyComment = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  comment_id: string,
  comment: string,
): SegmentTrackData => {
  return {
    eventName: events.REPLY_COMMENT,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      comment_id,
      comment,
    },
  };
};

const reportComment = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  comment_id: string,
): SegmentTrackData => {
  return {
    eventName: events.REPORT_COMMENT,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      comment_id,
    },
  };
};

const openMention = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_MENTION,
    payload: {
      opened: true,
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const closeMention = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
): SegmentTrackData => {
  return {
    eventName: events.CLOSE_MENTION,
    payload: {
      opened: false,
      museum_slug,
      booth_id,
      artwork_id,
    },
  };
};

const artworkEvents = {
  zoomIn,
  zoomOut,
  swipe,
  leave,
  moviePlay,
  voicePlay,
  openCaption,
  closeCaption,
  openRecentComment,
  closeRecentComment,
  postedComment,
  openFeedCaption,
  tapFeedComment,
  like,
  unlike,
  likeComment,
  unlikeComment,
  clickSort,
  openMention,
  replyComment,
  deleteComment,
  reportComment,
  closeMention,
};

export default artworkEvents;
