import { SegmentTrackData } from '../../../interfaces';

const events = {
  SIGNUP_USER: 'Signup User',
  UPDATE_MUSEUM_USER: 'Update Museum User',
};

const signupUser = (): SegmentTrackData => {
  return {
    eventName: events.SIGNUP_USER,
    payload: {
      type: 'organic',
    },
  };
};

const updateMuseumUser = (nickname: string): SegmentTrackData => {
  return {
    eventName: events.UPDATE_MUSEUM_USER,
    payload: {
      nickname,
    },
  };
};

const identifyEvents = { signupUser, updateMuseumUser };

export default identifyEvents;
