export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    PageDataInterface: [
      'MuseumActivityConnection',
      'MuseumArtworkCommentConnection',
      'MuseumArtworkLikedMuseumUserConnection',
      'NotificationConnection',
      'OwnerLogConnection',
      'PurchaseRequestConnection',
      'SellingOfferConnection',
      'SeriesConnection',
      'WorkConnection',
    ],
    SellingOfferInterface: [
      'SellingOfferAuction',
      'SellingOfferImmediate',
      'SellingOfferLottery',
      'SellingOfferPurchaseRequest',
    ],
    SellingOfferUnion: [
      'SellingOfferAuction',
      'SellingOfferImmediate',
      'SellingOfferLottery',
      'SellingOfferPurchaseRequest',
    ],
    TokenInformationInterface: [
      'SharedTokenInformation',
      'UniqueTokenInformation',
    ],
    TokenInformationUnion: ['SharedTokenInformation', 'UniqueTokenInformation'],
    WorkArticleUnion: [
      'WorkArticleHeaderRecord',
      'WorkArticleImageRecord',
      'WorkArticleParagraphRecord',
    ],
  },
};
export default result;
