import '../public/nprogress.css';

import { ApolloProvider } from '@apollo/client';
import { init } from '@sentry/node';
import { setupWorker } from 'msw';
import { setupServer } from 'msw/node';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';

import { handlers } from '../.mock/handlers';
import Analyzer from '../libs/analytics';
import { useApollo } from '../libs/apollo/client';
import Auth0Provider, { User } from '../libs/auth0/provider';
import { DefaultPageProps } from '../libs/ssr/getDefaultPageProps';
import GlobalStyle from '../libs/styled/global';
import { resources } from '../locales';
import i18nextConfig from '../next-i18next.config';
import { getAnalyticsIntegrations } from '../utils/local';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

if (process.env.MAINTENANCE_MODE === 'enabled') {
  if (typeof window === 'undefined') {
    const server = setupServer(...handlers);
    server.listen();
  } else {
    const client = setupWorker(...handlers);
    client.start();
  }
}

// MEMO: pokemon-card終了後にもとに戻す
// if (process.env.ANALYTICS_SEGMENT_WRITE_KEY) {
//   Analyzer.init('anique-museum', process.env.ANALYTICS_SEGMENT_WRITE_KEY);
// }

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => NProgress.done());

function MyApp(props: AppProps): JSX.Element {
  const { Component, pageProps, router } = props;

  const { session, apollo } = pageProps as DefaultPageProps;
  if (!session) {
    console.warn(
      'auth0: Must session in page props on getServerSideProps if you do action with auth0 token.',
    );
  }

  const client = useApollo(session, apollo?.cache.extract());

  // ref: https://github.com/vercel/next.js/issues/8592
  const err = (props as any).err;

  useEffect(() => {
    const integrations = getAnalyticsIntegrations() as Record<string, boolean>;

    if (!integrations) {
      return;
    }

    Analyzer.init(
      'anique-museum',
      process.env.ANALYTICS_SEGMENT_WRITE_KEY as string,
      integrations,
    );
  }, []);

  useEffect(() => {
    if (router.pathname) {
      Analyzer.page(router.pathname);
    }
  }, [router.pathname]);

  useEffect(() => {
    // 右クリック禁止
    document.oncontextmenu = () => false;
  }, []);

  return (
    <>
      <GlobalStyle />
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <meta
          name="viewport"
          content="width=device-width,user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,viewport-fit=cover"
        />
      </Head>
      <Auth0Provider user={session?.user as User}>
        <ApolloProvider client={client}>
          <Component {...pageProps} err={err} />
        </ApolloProvider>
      </Auth0Provider>
    </>
  );
}

// TODO: optionsをどこで持たせるか問題
const nextI18NextOptions = {
  ...i18nextConfig,
  resources,
};

export default appWithTranslation(MyApp, nextI18NextOptions);
