import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { NormalizedCacheObject, TypePolicies } from '@apollo/client/cache';
import Session from '@auth0/nextjs-auth0/dist/session/session';
import fetch from 'isomorphic-unfetch';
import { useMemo } from 'react';

import possibleTypesResult from '../../graphql/autogen/possibleTypesResult';

const aniqueMarket = {
  endpoint: process.env.GRAPHQL_ENDPOINT,
};

const datoCMS = {
  endpoint: process.env.DATO_CMS_ENDPOINT,
  token: process.env.DATO_CMS_API_TOKEN,
};

const typePolicies: TypePolicies = {
  Museum: {
    fields: {
      museumArtworkComments: {
        keyArgs: ['artworkDatoId'],
        merge: (existing, incoming) => {
          if (!existing) {
            return incoming;
          }

          const { isFirstPage } = incoming;
          if (isFirstPage) {
            return {
              ...incoming,
            };
          }

          return {
            ...existing,
            ...incoming,
            edges: [...existing.edges, ...incoming.edges],
          };
        },
      },
    },
  },
};

const createApolloClient = (
  token: string | undefined,
  initialState?: NormalizedCacheObject,
  links?: ApolloLink[],
): ApolloClient<NormalizedCacheObject> => {
  const isBrowser = typeof window !== 'undefined';
  // console.log('apollo#create', { token, initialState, ssr: !isBrowser });

  const httpLink = createHttpLink({
    credentials: 'same-origin',
    uri: aniqueMarket.endpoint,
    fetch,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  const httpDatoLink = createHttpLink({
    uri: datoCMS.endpoint,
    fetch,
    headers: { Authorization: `Bearer ${datoCMS.token}` },
  });

  const { possibleTypes } = possibleTypesResult;
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link: ApolloLink.from([
      ...(links ?? []),
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'dato',
        httpDatoLink,
        httpLink,
      ),
    ]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies,
    }).restore(initialState || {}),
  });
};

let _apollo: ApolloClient<NormalizedCacheObject> | null = null;
let _session: Session | null | undefined = null;

const initApollo = (
  session: Session | null | undefined,
  initialState?: NormalizedCacheObject,
  links?: ApolloLink[],
): ApolloClient<NormalizedCacheObject> => {
  if (typeof window === 'undefined') {
    return createApolloClient(session?.idToken, initialState, links);
  }

  if (_apollo === null || (session && _session === null)) {
    _session = session;
    _apollo = createApolloClient(session?.idToken, initialState);
  }

  return _apollo;
};

const useApollo = (
  session: Session | null | undefined,
  initialState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> => {
  const store = useMemo(() => initApollo(session, initialState), [
    session,
    initialState,
  ]);
  return store;
};

export default initApollo;
export { useApollo };
