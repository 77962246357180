const path = require('path');

const locales = [
  'ja',
  'en',
  'es',
  'fr',
  'de',
  'it',
  'ko',
  'zh_Hant',
  'th',
  'id',
];

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    locales,
    defaultLocale: 'ja',
  },
  // next-i18next options
  localeExtension: 'json',
  localePath: path.resolve('./locales'),
  localeStructure: '{{lng}}/{{ns}}',
  reloadOnPrerender: true,

  // react-i18next options
  fallbackLng: 'ja',
  supportedLngs: locales,
  defaultNS: 'translation',
  keySeparator: '.',
  // nsSeparator: '.',
};
