import resources from './resources';

// TODO: ハイフンをアンスコに変更する(next.config.jsの方がハイフン採用だった場合はそのまま)
const localeTypes = {
  Japanese: 'ja',
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  German: 'de',
  Italian: 'it',
  Korean: 'ko',
  Chinese: 'zh_Hant',
  Thai: 'th',
  Indonesian: 'id',
} as const;

export type Locale = typeof localeTypes[keyof typeof localeTypes];

export type LanguageOption = {
  label: string;
  value: Locale;
};

export { localeTypes, resources };
