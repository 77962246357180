import { SegmentTrackData, ShareAction } from '../../../interfaces';

const events = {
  OPEN_SHARE: 'Open Share Modal',
  SHARE: 'Tap Share Button',
};

const openShare = (
  museumSlug: string,
  boothId: string,
  artworkId: string,
): SegmentTrackData => {
  return {
    eventName: events.OPEN_SHARE,
    payload: {
      museum_slug: museumSlug,
      booth_id: boothId,
      artwork_id: artworkId,
    },
  };
};

const share = (
  museumSlug: string,
  boothId: string,
  artworkId: string,
  action: ShareAction,
): SegmentTrackData => {
  return {
    eventName: events.SHARE,
    payload: {
      museum_slug: museumSlug,
      booth_id: boothId,
      artwork_id: artworkId,

      action,
    },
  };
};

const shareEvents = { openShare, share };

export default shareEvents;
