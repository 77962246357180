import { SegmentTrackData } from '../../../interfaces';

const events = {
  SHOW_MUSEUM_SHOPIFY_ITEM_MODAL: 'Show Museum Shopify Item Modal',
  ADD_MUSEUM_SHOPIFY_CART_ITEM: 'Add Museum Shopify Cart Item',
  ENTER_MUSEUM_SHOPIFY_CHECKOUT: 'Enter Museum Shopify Checkout',
};

const showMuseumShopifyItemModal = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  product_handle: string,
  product_title: string,
): SegmentTrackData => {
  return {
    eventName: events.SHOW_MUSEUM_SHOPIFY_ITEM_MODAL,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      product_handle,
      product_title,
    },
  };
};

const addMuseumShopifyCartItem = (
  museum_slug: string,
  booth_id: string,
  artwork_id: string,
  product_handle: string,
  product_title: string,
): SegmentTrackData => {
  return {
    eventName: events.ADD_MUSEUM_SHOPIFY_CART_ITEM,
    payload: {
      museum_slug,
      booth_id,
      artwork_id,
      product_handle,
      product_title,
    },
  };
};

const enterMuseumShopifyCheckout = (
  museum_slug: string,
  booth_id: string,
): SegmentTrackData => {
  return {
    eventName: events.ENTER_MUSEUM_SHOPIFY_CHECKOUT,
    payload: {
      museum_slug,
      booth_id,
    },
  };
};

const shopifyEvents = {
  showMuseumShopifyItemModal,
  addMuseumShopifyCartItem,
  enterMuseumShopifyCheckout,
};

export default shopifyEvents;
