import { graphql } from 'msw';

export const handlers = [
  graphql.operation((_req, res, _ctx) => {
    return res((response) => {
      response.status = 503;
      response.body = '<title>Offline for Maintenance</title>';
      return response;
    });
  }),
];
