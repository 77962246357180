import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0 0 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", "Noto Sans JP", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    user-select: none;
    overscroll-behavior-y: none;
    overflow: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  div {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display:none;
    }
  }

  img {
    pointer-events: none;
  }
`;

export default GlobalStyle;
