import { SegmentTrackData } from '../../../interfaces';

const events = {
  SKIP: 'Skip Tutorial',
};

const skip = (museumSlug: string): SegmentTrackData => {
  return {
    eventName: events.SKIP,
    payload: {
      museum_slug: museumSlug,
    },
  };
};

const tutorialEvents = { skip };

export default tutorialEvents;
