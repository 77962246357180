import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import id from './id/translation.json';
import it from './it/translation.json';
import ja from './ja/translation.json';
import ko from './ko/translation.json';
import th from './th/translation.json';
import zh_Hant from './zh_Hant/translation.json';

export default {
  en,
  ja,
  es,
  fr,
  de,
  it,
  ko,
  zh_Hant,
  th,
  id,
};
